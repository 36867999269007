<template>
  <Home />
</template>

<script>
import { mapState } from 'vuex';
import Home from './views/Home';

export default {
  name: 'App',
  components: {
    Home,
  },
  computed: {
    ...mapState(['envProps']),
  },
  async mounted() {
    // Auto-tagging
    const { ExperimentationTags } = require('@vue-util/experimentation');
    await ExperimentationTags.fireAdobeTags();
  },

};
</script>

<style lang="scss">
@import "~appSCSS";
</style>
