// zoneOfRelevanceStore
const GET_BRAND = 'getBrand';
const GET_CARDS = 'getCards';
const GET_ZOR_CARDS = 'getZorCards';
const GET_INFO = 'getInfo';
const IS_ZONE_OF_RELEVANCE_ENABLED = 'isZoneOfRelevanceEnabled';
const IS_LOYALTY_FALLBACK = 'isLoyaltyFallback';

// loyaltyStore
const GET_REWARD_POINTS = 'getRewardPoints';

export {
  GET_BRAND,
  GET_CARDS,
  GET_INFO,
  IS_ZONE_OF_RELEVANCE_ENABLED,
  GET_ZOR_CARDS,
  IS_LOYALTY_FALLBACK,
  GET_REWARD_POINTS,
};
