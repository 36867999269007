/* eslint-disable import/prefer-default-export */
import { CARD_NAME, CARD_TITLE_VARIATIONS, CATEGORY_NAME_MAP } from '../constants';

const getCurrentVariationByIndex = (variations, index) => {
  const totalVariationsCount = variations.length;
  const currentIndex = index % totalVariationsCount;
  return variations[currentIndex];
};

const removeAllAtTheBegenning = (str) => {
  if (str.startsWith('All ')) {
    return str.replace('All ', '');
  }
  return str;
};

const mapCategoryName = (categoryName) => {
  if (Object.keys(CATEGORY_NAME_MAP).includes(categoryName)) {
    return CATEGORY_NAME_MAP[categoryName];
  }
  return categoryName;
};

const prepareCategoryName = (categoryName) => {
  let finalCategoryCardName = removeAllAtTheBegenning(categoryName);
  finalCategoryCardName = mapCategoryName(finalCategoryCardName);

  return finalCategoryCardName;
};

const replaceCardName = (variation, cardName) => {
  const values = [
    { oldValue: CARD_NAME.original, newValue: cardName },
    { oldValue: CARD_NAME.lowercased, newValue: cardName.toLowerCase() },
    {
      oldValue: CARD_NAME.capitalized,
      newValue: cardName.charAt(0).toUpperCase() + cardName.slice(1).toLowerCase(),
    },
  ];
  // eslint-disable-next-line no-restricted-syntax
  for (const currentElement of values) {
    if (variation.includes(currentElement.oldValue)) {
      return variation.replace(currentElement.oldValue, currentElement.newValue);
    }
  }
  return variation;
};

export const getFullCardName = ({
  cardName, type, index, isSignedIn, brand,
}) => {
  const userStatus = isSignedIn ? 'signedIn' : 'notSignedIn';

  const titleVariations = CARD_TITLE_VARIATIONS[brand][type][userStatus];
  const currentTitleVariation = getCurrentVariationByIndex(titleVariations, index);

  const preparedCardName = type === 'category' ? prepareCategoryName(cardName) : cardName;
  const fullCardName = replaceCardName(currentTitleVariation, preparedCardName);

  return fullCardName;
};
