import { modules } from './store_modules';

export default {
  namespaced: true,
  state() {
    return {};
  },
  getters: {},
  mutations: {},
  actions: {},
  modules,
};
