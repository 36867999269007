/* eslint-disable import/prefer-default-export, import/no-cycle */
import { isEmpty } from '../../../utils';
import { getFullCardName, addFullUrlToCard, moveProductIdToUrlBegining } from '.';

export const getCardsArrayFromObj = ({
  resultObj, type, originalKeySequenceArray, isSignedIn, brand,
}) => {
  const resultArray = [];

  if (type === 'sale') {
    const saleCard = resultObj.sale;

    saleCard.products.filter(Boolean).forEach((currentSaleProduct, index) => {
      const fullCardName = getFullCardName({
        cardName: saleCard.cardName,
        type,
        index,
        isSignedIn,
        brand,
      });
      const newCardUrl = moveProductIdToUrlBegining({
        url: saleCard.cardUrl,
        productId: currentSaleProduct.id,
      });

      const currentSaleCard = {
        ...saleCard,
        cardName: fullCardName,
        cardUrl: newCardUrl,
        products: [currentSaleProduct],
      };
      const cardWithFullUrl = addFullUrlToCard(currentSaleCard);

      resultArray.push(cardWithFullUrl);
    });
  } else {
    originalKeySequenceArray.forEach((key, index) => {
      const card = resultObj[key];
      if (!isEmpty(card.products)) {
        const fullCardName = getFullCardName({
          cardName: card.cardName,
          type,
          index,
          isSignedIn,
          brand,
        });
        const cardWithFullName = { ...card, cardName: fullCardName };
        const cardWithFullUrl = addFullUrlToCard(cardWithFullName);

        resultArray.push(cardWithFullUrl);
      }
    });
  }

  return resultArray;
};
