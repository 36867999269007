/* eslint-disable import/prefer-default-export, no-console */
const $http = require('@vue-util/http');

export const fetchProductById = async (productId) => {
  try {
    const url = `/xapi/digital/v1/product/${productId}`;
    const response = await $http.get(url);

    if (response.errors || response.Error) {
      throw new Error(
        response.errors
          ? response.errors.error[0].message
          : response.Error.message,
      );
    }

    return response.data?.product?.[0];
  } catch (error) {
    console.error(error);
    return {};
  }
};
