/**
 * The main entry point for the client.
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import logger from '@core/util/universal-logger';
import createApp from './create-app';

// eslint-disable-next-line import/no-dynamic-require
const { rootContainer } = require(process.env.PACKAGE_MAIN);
// eslint-disable-next-line import/no-dynamic-require
const { Apps } = require(process.env.PACKAGE_MAIN);

export default ({ router, store }) => {
  // This is how state data is passed between the server and client. Populated by context.state set in entry.server.js.
  // Should be called before createApp() so that naviation hooks have access to the store's state
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__);
    const ASSET_HOST = store.state.envProps ? store.state.envProps.assetHost : '';

    if (ASSET_HOST && __webpack_public_path__.indexOf('http') !== 0 && __webpack_public_path__.indexOf('//') !== 0) { // eslint-disable-line no-undef, camelcase
      __webpack_public_path__ = ASSET_HOST + __webpack_public_path__; // eslint-disable-line no-undef, camelcase
    }
  }

  const mountFunctions = [];
  if (Apps) {
    Apps.forEach((app) => {
      const appinstance = createApp(router, store, { cookies: document.cookie, logger }, app.component);
      mountFunctions.push(() => appinstance.mount(app.container));
    });
  } else {
    const appinstance = createApp(router, store, { cookies: document.cookie, logger });
    mountFunctions.push(() => appinstance.mount(rootContainer || '#app'));
  }

  if (router) {
    router.isReady()
      .then(() => {
        mountFunctions.forEach((mount) => {
          mount();
        });
      })
      .catch((err) => {
        if (err.externalRedirect) {
          window.location.href = window.location.origin + err.externalRedirect;
        } else {
          throw err;
        }
      });
  } else {
    mountFunctions.forEach((mount) => {
      mount();
    });
  }
};
