/* eslint-disable import/prefer-default-export */
export const addFullUrlToCard = (card) => {
  const {
    cardUrl, cardName, cardType, products, zone, treatmentId,
  } = card;

  const isLandingPageUrl = cardUrl.includes('/shop/featured');
  if (!isLandingPageUrl) {
    return card;
  }

  let fullCardUrl;
  const encodedCategoryName = encodeURIComponent(cardName);
  if (cardType === 'rvi') {
    fullCardUrl = `${cardUrl}?isZoneOfRelevance=true&categoryName=${encodedCategoryName}`;
  } else {
    const modelId = products[0].choiceId ? products[0].choiceId.substring(3, 6) : '';
    fullCardUrl = `${cardUrl}?isZoneOfRelevance=true&categoryName=${encodedCategoryName}&prosSource=col&prosContent=Zor&prosZone=${
      zone || ''
    }&prosChoiceId=${products[0].choiceId || ''}&prosModelId=${modelId}&prosMilanId=${treatmentId || ''}`;
  }

  const cardWithFullUrl = {
    ...card,
    cardUrl: fullCardUrl,
  };

  return cardWithFullUrl;
};
