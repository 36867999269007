<template>
  <main id="app-wrapper">
    <!-- eslint-disable-next-line vue/require-component-is -->
    <component :is="App" />
  </main>
</template>

<script>
// eslint-disable-next-line import/no-dynamic-require
const { App } = require(process.env.PACKAGE_MAIN);

if (!App) {
  throw new Error('Expected package to export a Vue App');
}

export default {
  name: 'AppWrapper',
  setup() {
    return { App };
  },
};
</script>

<style>
html {
  height: 100%;
}
</style>
