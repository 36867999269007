/* istanbul ignore file */
/**
 * Example using code splitting and componentization. The run-time imports will cause bundle split
 * points for webpack, and as a result, load chunks on-demand (when navigating between routes).
 *
 * @see https://webpack.js.org/guides/code-splitting/#dynamic-imports
 */

export default () => [
  {
    name: 'home',
    path: '/',
    component: () => import('./app'),
  },
  {
    name: 'wgl',
    path: '/navigation/header-footer',
    component: () => import('./app'),
  },
];
