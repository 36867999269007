/* eslint-disable no-restricted-syntax, import/prefer-default-export, import/no-cycle */
import { isEmpty } from '../../../utils';
import {
  makeRecommendedItemsToCardsFunction,
  getOrderedProsCardsArray,
  getRecommendations,
} from '.';
import { PROS_CARDS_SEQUENCE } from '../constants';

const isEnoughZoneInfo = (zoneInfo) => zoneInfo && zoneInfo.context && zoneInfo.maxRecs;

const isRecommendedItemsAvailable = (recommendedItems) => recommendedItems && recommendedItems.length;

// Function will return empty array if:
// - 2 or more zones has not enough pros zones information
// - 2 or more zones do not get proper data during request
// - 2 or more zones has empty array of cards
export const getProsCards = async ({
  prosZonesInfo,
  brand,
  isSignedIn,
  deviceType,
}) => {
  let availableProsZones = [
    {
      type: 'category',
    },
    {
      type: 'brand',
    },
    {
      type: 'sale',
    },
  ];
  const deleteProsZoneByType = (type) => {
    availableProsZones = availableProsZones.filter(
      (elem) => elem.type !== type,
    );
  };

  // CHECK prosZonesInfo: do we have zone info (coming from canvas, getting from HTML) to make a request
  for (const prosZone of availableProsZones) {
    prosZone.info = prosZonesInfo[prosZone.type];

    if (!isEnoughZoneInfo(prosZone.info)) {
      deleteProsZoneByType(prosZone.type);
    }
    if (availableProsZones.length < 2) {
      return [];
    }
  }

  // Get data from the server
  const promises = [];
  for (const prosZone of availableProsZones) {
    promises.push(
      getRecommendations({
        zone: prosZone.info.context,
        maxRecsCount: prosZone.info.maxRecs,
        brand,
        deviceType,
      }),
    );
  }
  const prosData = (await Promise.allSettled(promises)).map(
    (elem) => elem.value,
  );

  // CHECK recommendedItems: do we have at least 2 zones with successfully passed requests
  for (const [index, prosZone] of availableProsZones.entries()) {
    prosZone.prosData = prosData[index].data;
    prosZone.recommendedItems = prosZone.prosData
      ? prosZone.prosData.recommendedItems
      : [];

    if (!isRecommendedItemsAvailable(prosZone.recommendedItems)) {
      deleteProsZoneByType(prosZone.type);
    }
    if (availableProsZones.length < 2) {
      return [];
    }
  }

  // CHECK cards: do we have at least 2 zones with not empty cards array
  const recommendedItemsToCards = makeRecommendedItemsToCardsFunction({
    brand,
    isSignedIn,
  });
  for (const prosZone of availableProsZones) {
    prosZone.cards = recommendedItemsToCards({
      recommendedItems: prosZone.recommendedItems,
      type: prosZone.type,
      treatmentId: prosZone.prosData.treatmentId,
      zone: prosZone.info.context,
    });

    if (isEmpty(prosZone.cards)) {
      deleteProsZoneByType(prosZone.type);
    }
    if (availableProsZones.length < 2) {
      return [];
    }
  }

  // Put in the right order
  const prosCardsSequence = PROS_CARDS_SEQUENCE[brand];

  const prosCardsObj = {};
  for (const prosZone of availableProsZones) {
    prosCardsObj[prosZone.type] = prosZone.cards;
  }
  return getOrderedProsCardsArray(prosCardsObj, prosCardsSequence);
};
