<template>
  <BaseHeader
    v-show="isNavigationLoaded"
    v-bind="customAttrs"
  />
</template>

<script setup>
/* istanbul ignore file */
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { BaseHeader } from '@common-vue/navigation';
import useStoreModule from '@common-vue/navigation/src/composables/dumb/useStoreModule';
import storeModules from '@common-vue/navigation/src/store_modules';

const store = useStore();
const isNavigationLoaded = computed(() => store.state.isNavigationLoaded);
const customAttrs = ref({});

Object.entries(storeModules).forEach(([key, value]) => {
  useStoreModule(store, key, value);
});

const $el = document.querySelector('#nav-header');
let envProps = window.__NAVIGATION__ENV_PROPS__;
let context = {};
let attrs = {};

if ($el) {
  const { envProps: envPropsStr = '{}', context: contextStr = '{}', ...datasetAttrs } = $el.dataset;

  context = JSON.parse(contextStr);
  envProps = envProps || JSON.parse(envPropsStr);
  attrs = datasetAttrs;
}

customAttrs.value = attrs;
store.commit('setEnvProps', envProps);
store.commit('setContextParams', context);
store.commit('setNavigationLoaded');
</script>

<style lang="scss">
@import "~appSCSS";
</style>
