<template>
  <BaseFooter
    v-show="isNavigationLoaded && isMounted"
    v-bind="customAttrs"
  />
</template>

<script setup>
/* istanbul ignore file */
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { BaseFooter } from '@common-vue/navigation';

const store = useStore();
const isNavigationLoaded = computed(() => store.state.isNavigationLoaded);
const isMounted = ref(false);
const customAttrs = ref({});

onMounted(() => {
  const $el = document.querySelector('#nav-footer');

  if ($el) {
    customAttrs.value = $el.dataset;
  }

  isMounted.value = true;
});

</script>

<style lang="scss">
@import "~appSCSS";
</style>
