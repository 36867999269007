/* eslint-disable import/prefer-default-export */
/**
 * Put cards in given order
 * @param  {Array} cards Cards array
 * @param  {Array} prosCardsSequence Cards sequence (array with "sale", "category", "brand" in a particular order)
 * @returns {Array} - Ordered cards array
 */
export const getOrderedProsCardsArray = (cards, prosCardsSequence) => {
  const currentIndexes = {
    sale: 0,
    category: 0,
    brand: 0,
  };
  const resultArray = [];

  prosCardsSequence.forEach((currentCardType) => {
    if (cards[currentCardType]) {
      const elementToPick = cards[currentCardType][currentIndexes[currentCardType]];
      if (elementToPick) {
        resultArray.push(elementToPick);
        currentIndexes[currentCardType] += 1;
      }
    }
  });

  return resultArray;
};
