import { Cookie } from '@common-vue/util/src/modules/cookie';

const loadIshipWelcomePopup = (context, brand) => {
  if (!context?.domestic && context?.deviceType !== 'PHONE') {
    const welcomePopup = Cookie.get('welcomePopup') || 'false';
    if (welcomePopup === 'false') {
      const merchId = brand === 'mcom' ? '2181' : '2180';
      const script = document.createElement('script');
      script.type = 'text/javascript';
      document.body.appendChild(script);
      script.src = `https://wm.prd.borderfree.com/welcome/welcome.srv?merchId=${merchId}&countryId=${context?.regionCode}&setCookie=Y`;
      Cookie.set('welcomePopup', true);
    }
  }
};
export default loadIshipWelcomePopup;
