/**
 * Application router, used by both the client and server.
 *
 * @see https://ssr.vuejs.org/en/routing.html
 */

import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router';

const isClient = process.env.APP_ENV === 'client';

export default function createVueRouter(routes, store) {
  const router = createRouter({
    history: isClient ? createWebHistory() : createMemoryHistory(),
    routes: routes({ store }),
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.meta?.noScroll) {
        return {};
      }
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth',
        };
      }
      return {
        top: 0,
        behavior: 'smooth',
      };
    },
  });

  return router;
}
