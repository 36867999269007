import App from './src/app';
import Header from './src/header';
import Footer from './src/footer';
import routes from './src/routes';
import store from './src/store';

try {
  // eslint-disable-next-line camelcase, no-undef
  if (__webpack_public_path__.startsWith('/') && typeof window !== 'undefined') {
    let assetHost;

    if (window.__NAVIGATION__ENV_PROPS__) {
      assetHost = window.__NAVIGATION__ENV_PROPS__.assethost;
    } else {
      const headerWrapper = document.getElementById('nav-header');
      const envProps = JSON.parse(headerWrapper.dataset.envProps);
      assetHost = envProps.assethost;
    }

    // eslint-disable-next-line camelcase, no-undef
    __webpack_public_path__ = `${assetHost}${__webpack_public_path__}`; // Change public path for CSR
  }
} catch (e) {
  // eslint-disable-next-line no-console
  console.error('[navigation-wgl]: Failed to change __webpack_public_path__', e);
}

const Apps = [
  {
    component: Header,
    container: '#nav-header',
  },
  {
    component: Footer,
    container: '#nav-footer',
  },
];

export {
  App,
  routes,
  store,
  Apps,
};
