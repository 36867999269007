/* eslint-disable import/prefer-default-export */
export const moveProductIdToUrlBegining = ({ url, productId }) => {
  if (!url.includes(`${productId}%7C`) && !url.includes(`%7C${productId}`)) {
    return url;
  }
  let resultUrl = url;
  // --- STEP 1: remove id ---
  if (url.includes(`${productId}%7C`)) {
    // Case 1: id in the begining or middle of the string
    resultUrl = url.replace(`${productId}%7C`, '');
  } else if (url.endsWith(`%7C${productId}`)) {
    // Case 2: id in the end of the string
    resultUrl = url.replace(`%7C${productId}`, '');
  }
  // --- STEP 2: paste it in the begenning of the URL ---
  resultUrl = resultUrl.replace('/shop/featured/', `/shop/featured/${productId}%7C`);
  return resultUrl;
};
