const ZOR_TITLE = {
  mcom: {
    notSignedIn: 'Our picks for you',
    signedIn: 'Our picks for you',
  },
  bcom: {
    notSignedIn: 'MORE GREAT FINDS',
    signedIn: 'RECOMMENDED FOR YOU',
  },
};

export default ZOR_TITLE;
