/* eslint-disable no-shadow */
import { GET_REWARD_POINTS } from '@types/getters';
import { SET_REWARD_POINTS } from '@types/mutations';
import { FETCH_REWARD_POINTS } from '@types/actions';

import getRewardPoints from './getRewardPoints';

export function state() {
  return {
    rewardPoints: {},
  };
}
export const getters = {
  [GET_REWARD_POINTS](state) {
    return state.rewardPoints;
  },
};

export const mutations = {
  [SET_REWARD_POINTS](state, payload) {
    state.rewardPoints = payload;
  },
};

export const actions = {
  async [FETCH_REWARD_POINTS](
    {
      commit,
    },
  ) {
    const rewardPointsData = await getRewardPoints();
    commit(SET_REWARD_POINTS, rewardPointsData);
  },
};
