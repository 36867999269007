const SET_CANVAS = 'setCanvas';
const SET_CANVAS_ERROR_STATE = 'setCanvasErrorState';
const SET_CANVAS_API_ERROR_RESPONSE = 'setCanvasApiErrorResponse';
const SET_REDIRECT = 'setRedirect';
const SET_ZOR_INFO = 'setZorInfo';

// zoneOfRelevanceStore
const SET_RVI_IDS = 'setRviIds';
const SET_ZOR_CARDS = 'setZorCards';
const SET_IS_LOYALTY_FALLBACK = 'setIsLoyaltyFallback';
const SET_PROS_ZONES_INFO = 'setProsZonesInfo';

// loyaltyStore
const SET_REWARD_POINTS = 'setRewardPoints';

export {
  SET_CANVAS,
  SET_CANVAS_ERROR_STATE,
  SET_CANVAS_API_ERROR_RESPONSE,
  SET_REDIRECT,
  SET_RVI_IDS,
  SET_ZOR_INFO,
  SET_ZOR_CARDS,
  SET_IS_LOYALTY_FALLBACK,
  SET_PROS_ZONES_INFO,
  SET_REWARD_POINTS,
};
