/* eslint-disable import/prefer-default-export */
import { IMAGE_SRC_BEGINNING } from '../constants';

export const getImageSrc = ({ filePath, imageId, brand }) => {
  if (!filePath && !imageId) {
    return '';
  }
  const imgSrcBeginning = IMAGE_SRC_BEGINNING[brand];
  const imgFilePath = filePath || `0/optimized/${imageId}_fpx.tif`;
  return `${imgSrcBeginning}/${imgFilePath}`;
};
