<template>
  <ZoneOfRelevanceTemplate
    :is-loyalty-fallback="isLoyaltyFallback"
    :is-desktop="isDesktop"
    :zor-cards="zorCards"
    :brand="brand"
    :carousel-title="sectionTitle"
  >
    <template #zor-slot>
      <component
        :is="loyaltyComponent"
        :full-width="isTablet || (isLoyaltyFallback && isDesktop)"
      />
    </template>
  </ZoneOfRelevanceTemplate>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { ZoneOfRelevance as ZoneOfRelevanceTemplate } from '@common-vue/canvas-aem/src/components/zoneOfRelevance';
import {
  IS_LOYALTY_FALLBACK,
} from '@types/getters';
import ZOR_TITLE from './constants';

import { userInfo } from '../../utils/userInfo';

const store = useStore();
const isDesktop = computed(() => store.state.envProps?.headers?.deviceType === 'DESKTOP');
const isTablet = computed(() => store.state.envProps?.headers?.deviceType === 'TABLET');
const brand = computed(() => store.state?.envProps?.BRAND);
const isLoyaltyFallback = computed(
  () => store.getters[`zoneOfRelevanceStore/${IS_LOYALTY_FALLBACK}`],
);
defineProps({
  zorCards: {
    type: Array,
    default: () => [],
  },
});
const isSignedIn = userInfo.isSignedIn();
const userStatus = isSignedIn ? 'signedIn' : 'notSignedIn';

const sectionTitle = computed(() => ZOR_TITLE[brand?.value]?.[userStatus] || '');

const LoyaltyCard = brand.value === 'mcom' ? () => import('../mcom/LoyaltyCard') : () => import('../bcom/LoyaltyCard');
const loyaltyComponent = computed(
  () => defineAsyncComponent({
    loader: LoyaltyCard,
    onError: () => { },
  }),
);
</script>
