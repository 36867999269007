import createVueRouter from './router';
import createStore from './create-store';

export default (context = {}) => {
  const { store } = createStore(context);
  // eslint-disable-next-line import/no-dynamic-require
  const { routes } = require(process.env.PACKAGE_MAIN);
  let router;

  if (routes) {
    router = createVueRouter(routes, store);
  }

  return { router, store };
};
