import createVueStore from './store';

export default (context = {}) => {
  // eslint-disable-next-line import/no-dynamic-require
  const { store: _appStore } = require(process.env.PACKAGE_MAIN);
  const appStore = { ..._appStore };
  let store;

  if (appStore) {
    const _state = typeof appStore.state === 'function' ? appStore.state() : appStore.state;

    if (context.envProps && _state) {
      Object.assign(_state, {
        envProps: context.envProps,
      });
    }
    if (context.killswitches && _state) {
      Object.assign(_state, {
        killswitches: context.killswitches,
      });
    }
    if (context.pageData && _state) {
      Object.assign(_state, {
        pageData: context.pageData,
      });
    }

    // Handle injection of experiments data into the store
    if (context.experiments && _state) {
      try {
        Object.assign(_state, {
          experiments: JSON.parse(context.experiments),
        });
      } catch (e) {
        console.log(`Failed setting experimentation: [${context.experiments}] ${e}`); // eslint-disable-line no-console
      }
    }

    if (_state) {
      appStore.state = () => _state;
    }

    appStore.getters = {
      getKillswitches: (state) => () => {
        if (state && state.killswitches) {
          return state.killswitches;
        }
        return {};
      },
      // Global getters for experimentation data
      getExpIds: (state) => () => {
        if (state && state.experiments && state.experiments.EXPERIMENT) {
          return state.experiments.EXPERIMENT;
        }
        return [];
      },
      hasExpId: (state) => (id) => {
        if (state && state.experiments && state.experiments.EXPERIMENT) {
          return state.experiments.EXPERIMENT.includes(id);
        }
        return false;
      },
      ...appStore.getters,
    };

    // Global actions for experimentation data
    appStore.actions = {
      setExpIds({ commit }, expArray) {
        commit('setExpData', expArray);
      },
      ...appStore.actions,
    };

    // Global mutations for experimentation data
    appStore.mutations = {
      setExpData(state, data) {
        state.experiments = { ...state.experiments, EXPERIMENT: data };
      },
      ...appStore.mutations,
    };

    store = createVueStore(appStore);
  }

  if (module.hot) {
    // accept actions and mutations as hot modules
    module.hot.accept(process.env.PACKAGE_MAIN, () => {
      // eslint-disable-next-line import/no-dynamic-require
      const { store: s } = require(process.env.PACKAGE_MAIN);
      store.hotUpdate({ ...s });
    });
  }

  return { store };
};
