const MISCGCS = 'MISCGCs';
const RTD_COOKIE_NAME = 'RTD';
const STATE_COOKIE_NAME = 'USERST';
const SHIPPING_COUNTRY_COOKIE_NAME = 'shippingCountry';
const ZIP_CODE_COOKIE_NAME = 'USERPC';
const CUSTOMER_ID_COOKIE_NAME_MCOM = 'macys_online_uid';
const CUSTOMER_ID_COOKIE_NAME_BCOM = 'bloomingdales_online_uid';
const SIGNED_IN_COOKIE_NAME = 'SignedIn';
const ADOBE_COOKIE_NAME = 'AMCV_8D0867C25245AE650A490D4C@AdobeOrg';

export {
  MISCGCS,
  RTD_COOKIE_NAME,
  STATE_COOKIE_NAME,
  SHIPPING_COUNTRY_COOKIE_NAME,
  ZIP_CODE_COOKIE_NAME,
  CUSTOMER_ID_COOKIE_NAME_MCOM,
  CUSTOMER_ID_COOKIE_NAME_BCOM,
  SIGNED_IN_COOKIE_NAME,
  ADOBE_COOKIE_NAME,
};
